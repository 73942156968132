







































import { Component, Vue, Prop } from "vue-property-decorator";
import { RouterNames } from "@/router/routernames";
@Component({ components: {} })
export default class EmpresarialOpciones extends Vue {
  public dialog = false;
  public counterclickOp: any[] = [];
  created() {}

  public get allbuttons() {
    return [
      {
        id: 1,
        titulo: "Agenda",
        descripcion: "Gráficos de tus citas",
        icono: "fad fa-calendar",
        routername: RouterNames.dashboard_agenda
      },
      {
        id: 2,
        titulo: "Pacientes",
        descripcion: "Gráficos de avences de los pacientes",
        icono: "fad fa-users",
        routername: RouterNames.dashboard_pacientes
      },
      {
        id: 3,
        titulo: "Consultas",
        descripcion: "Gráficos consultas",
        icono: "fad fa-apple-alt",
        routername: RouterNames.dashboard_entrevista
      }/*,
      {
        id: 4,
        titulo: "Sistema",
        descripcion: "Dashboard Sistema",
        icono: "fad fa-tachometer-slowest",
        routername: RouterNames.dashboard_sistema
      }*/
    ];
  }

  public showPopup(): boolean {
    const result = this.counterclickOp.filter(
      item => this.$route.name === item.routername
    );

    return result.length >= 3;
  }
  public clickbutton(item: any) {
    if (this.$route.name === item.routername) {
      this.counterclickOp.push(item);
      this.dialog = this.showPopup();
      return;
    }

    this.$router.push({
      name: item.routername
    });
  }
}
